<template>
    <div>
        <div class="mb-4 sm:mb-6 w-11/12 mr-auto ml-auto rounded-lg shadow-lg border sm:w-9/12 bg-white border-lightborder dark:bg-black200 dark:border-border100 dark:shadow"
            style="box-shadow:0 0.5rem 1.2rem rgba(82, 85, 92, .15);">
            <div class="py-3 px-2 mr-auto ml-auto">
                <div class="flex justify-start items-center flex-wrap sm:flex-nowrap px-2">
                    <div class="text-black dark:text-white300 text-base font-bold sm:text-xl pr-3">{{
                        $t('branch.currentBranch')
                    }}:</div>
                    <div class="flex justify-start items-center">
                        <div :class="index !== 0 ? 'ml-2' : ''" v-for="(item, index) in branchList" :key="index">
                            <el-radio @input="enabled(item)" v-model="chainid" :label="item.chainid">{{ item.name
                                }}</el-radio>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-2 w-11/12 sm:w-9/12 mr-auto ml-auto">
            <module-title :title="$t('Rank.rich')" :total="totalAddress"></module-title>
        </div>
        <div
            class=" mb-4 w-11/12 sm:w-9/12 mr-auto ml-auto rounded-lg shadow-lg border bg-white border-lightborder dark:bg-black200 dark:border-border100 dark:shadow">
            <rank-list-table :dataList="rankListDatas" :loadStatus="rankListLoadStatus" />
            <div class="">
                <h-pagination @changePageSize="toRankFirstPage" @toFirstPage="toRankFirstPage"
                    @toPrePage="toRankPrePage" @toNextPage="toRankNextPage" @toLastPage="toRankLastPage"
                    :currentPage="rankCurrentPage" :totalPage="totalPage"
                    @toTargetPage="toRankTargetPage"></h-pagination>
            </div>
        </div>
    </div>
</template>


<script>

import ModuleTitle from '@/components/public/ModuleTitle'
import HPagination from '@/components/public/HPagination'
import RankListTable from '@/components/child/RankListTable'
import { rankList } from '@/request/rank';
import { branchList } from '@/request/branch'
import { numberFormat } from '../../utils/format'
export default {
    name: "Rank",
    components: { RankListTable, ModuleTitle, HPagination, },
    data() {
        return {
            rankListDatas: [],
            rankListLoadStatus: 'loading',
            rankPageSize: 10,
            rankCurrentPage: 1,
            totalPage: 1,
            totalAddress: 0,
            branchList: [],
            chainid: 0
        }
    },
    mounted() {
        this.getRankList()
        this.getBranchList()
    },
    methods: {
        numberFormat,
        getBranchList() {
            branchList().then(res => {
                console.log(res)
                res.data.map(item => {
                    this.branchList.push(item)
                })
                if (localStorage.getItem('chainID')) {
                    this.chainid = Number(localStorage.getItem('chainID'))
                }
                console.log(this.chainid)
                this.chainid = Number(this.chainid)
                console.log(this.chainid)
            })
                .catch(err => {
                    console.log(err)
                })
        },
        enabled(item) {
            console.log(item)
            localStorage.setItem('chainName', item.name)
            localStorage.setItem('chainID', item.chainid)
            localStorage.setItem('symbol', item.symbol)
            this.$store.commit('getChainId', item.chainid)
            this.$store.commit('getChainName', item.name)
            console.log(item.chainid, localStorage.getItem('chainID'), this.$store.state.chainName, item.checked)
            console.log(localStorage.getItem('chainID'))
            window.location.reload()
        },
        getRankList() {
            this.rankListLoadStatus = 'loading'
            rankList({ pageSize: this.rankPageSize, page: this.rankCurrentPage, chainid: localStorage.getItem('chainID') }).then(res => {
                if (res.data.length !== 0) {
                    this.rankListDatas = res.data
                    this.rankListLoadStatus = 'finished'
                } else {
                    this.rankListLoadStatus = 'empty'
                    this.$message.error(this.$t('messageTips.noMore'))
                }
                // this.totalAddress = this.$t('moduleTitle.totalRanks', { count: numberFormat(res.total) })
                this.totalAddress = res.total
                this.totalPage = res.totalPage
                this.rankCurrentPage = res.page
            })
        },
        toRankFirstPage(selectedPageSize) {
            console.log('第一页')
            this.rankPageSize = selectedPageSize
            this.rankCurrentPage = 1
            this.rankListDatas = []
            this.getRankList()

        },
        toRankPrePage(selectedPageSize) {
            if (this.rankCurrentPage === 1) {
                return
            }
            this.rankPageSize = selectedPageSize
            this.rankCurrentPage = this.rankCurrentPage - 1
            this.rankListDatas = []
            this.getRankList()
        },
        toRankNextPage(selectedPageSize) {
            console.log(this.rankCurrentPage, this.totalPage)

            if (this.rankCurrentPage >= this.totalPage) {
                return
            }
            this.rankPageSize = selectedPageSize
            this.rankCurrentPage = this.rankCurrentPage + 1
            this.rankListDatas = []
            this.getRankList()
        },
        toRankLastPage(selectedPageSize) {
            console.log(this.rankCurrentPage, this.totalPage)
            if (this.rankCurrentPage >= this.totalPage) {
                return
            }
            this.rankPageSize = selectedPageSize
            this.rankCurrentPage = this.totalPage
            this.rankListDatas = []
            this.getRankList()
        },
        toRankTargetPage(selectedPageSize, targetPage) {
            console.log(targetPage)
            if (targetPage <= 0) {
                return
            }
            this.rankPageSize = selectedPageSize
            this.rankCurrentPage = targetPage
            this.rankListDatas = []
            this.getRankList()
        }
    }
}
</script>

<style></style>